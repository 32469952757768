<template>
    <admin-ui>

    <template v-slot:title>
   Edit Form
  </template>
              

        <form-partials />

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="relative my-10">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
  <div class="relative flex justify-center">
    <span class="px-3 bg-white text-lg font-medium text-gray-900">
      Submissions
    </span>
  </div>
</div>


            <!-- This example requires Tailwind CSS v2.0+ -->
<div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
    
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                From data
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Odd row -->
            <tr class="bg-white" v-for="submission in submissions.data" :key="submission.id">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{$date(submission.created_at).format('D MMM YYYY HH:mm')}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
               {{submission.from}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a href="#" class="text-indigo-600 hover:text-indigo-900">View</a>
              </td>
            </tr>

          

            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<form-pagination :data="submissions" @pagination-change-page="getSubmissions"/>

    </admin-ui>
</template>

<script>
import FormPagination from '@/components/form-pagination'
import FormPartials from '@/views/Partials/Form-partials'
import adminUi from '@/components/admin-ui'
export default {
    components: {FormPagination, adminUi,FormPartials},
    created(){
      this.getSubmissions();
      console.log(this.$route.params.id);
    },
    data(){
      return {
        submissions: false
      }
    },
    methods: {
      getSubmissions(page = 0){
        this.$axios.get('/submissions?page='+page+'&id='+this.$route.params.id).then((response)=>{
          this.submissions = response.data;
        });
      }
    }
}
</script>
